import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import CtaDisclosure from "@tightrope/ctadisclosure"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import SimplyTemp from "src/components/modules/simplyTemp"

const prdbestData = import("./data/data.json");

const Img = () => <img className="cws" src="/img/cws-badge.png" data-cy="cta" onClick = {() => window.triggerInstall()} />;
const Img2 = () => <img className="as" src="/img/as-badge.png" data-cy="cta" onClick = {() => window.triggerInstall()} />;

export default function prdbest() {

  let module;
   module = null;
  if(isChrome()){
     module = <Trbm data={prdbestData}><Img /><CtaDisclosureSearch data={prdbestData}></CtaDisclosureSearch></Trbm>
   }
   if(isFirefox()){
     module = <Trbm data={prdbestData}><Img /><CtaDisclosure data={prdbestData}></CtaDisclosure></Trbm>
   }
   else if(isSafari()){
    module = <SimplyTemp data={prdbestData}><Img2 /></SimplyTemp>;
  }
  else if(isIeEdge()){
    module = <SimplyTemp data={prdbestData}></SimplyTemp>;
  }

   let css;
     if(isFirefox()){
       css = `.trbm-module--trbm .trbm-module--bullets {
         padding-left: 65px;
         list-style: circle;
         color: #333;
         font-weight: lighter;
       }
       .cws {
         display: none;
       }
         .trbm-module--trbm button {
           padding: 20px 80px!important;
         }
       #ctadisclosure-module--ctadisclaimer {
           padding: 25px 7% 0;
         }
       }`;
     }
     else if(isIeEdge()){
      css = `
      .simply-module--simplyWrap button.simply-module--install:hover {
        transform: none !important;
        cursor: auto !important;
        }
        .simply-module--simplyWrap button.simply-module--install {
        cursor:auto !important; 
      }
      }`;
    }
     else{
       css = `.trbm-module--trbm .trbm-module--bullets {
         padding-left: 0;
       }
       .cws {
         width: 200px;
         display: block;
         margin: 10px auto;
         cursor:pointer;
       }
       .as {
        width: 150px;
        display: block;
        margin: 10px auto;
        cursor:pointer;
      }
       #ctadisclosure-module--ctadisclaimer {
         padding: 0px 7%;
         padding-bottom: 0;

       }`;
     }

  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">
      {`
        body {
          background-color: #e5e5e5;
        }
        h1 {
          margin: 0 auto !important;
        }
        .trbm-module--logo {
          margin: 0px 0px 10px !important;
          width: 130px !important;
        }

        .trbm-module--trbm .trbm-module--steps {
          display: none;
        }

        .trbm-module--trbm button {
          background: #43ba1a;
          padding: 20px 50px;
        }

        .trbm-module--card {
          padding: 20px 20px 30px;
        }

        #ctadisclosuresearch-module--ctadisclaimersearch {
          padding: 0px 7%;
          padding-bottom: 0;
        }

        .trbm-module--trbm h2 {
          margin-top: 7px;
        }

        .simply-module--simplyWrap h1, .simply-module--simplyWrap p {
          color:#333 !important;
        }
        .simply-module--simplyWrap .simply-module--atf {
          max-height:auto !important;
          background: #fff;
          width: 800px !important;
          border-radius: 10px;
          border: 1px solid #ccc;
          text-align: center;
          box-sizing: border-box;
          padding: 20px !important;
          margin: 40px auto !important;
        }

        .simply-module--minHeight {
          min-height:100vh;
          max-height:1080px;
        }

      `}
         </style>

         <style type="text/css">
           {css}
               </style>
      <title>Freshy Search - freshysearch.com</title></Helmet>
      {module}
    </HomepageLayout>
  )
}
